import { createSlice } from "@reduxjs/toolkit";
import { EditorState } from "draft-js";

import * as settingsActions from "./settings.actions";
import settingsAsyncActions from "./settings.asyncActions";

import { ISettingsInitialState } from "./settings.interfaces";

const initialState: ISettingsInitialState = {
  ethnicities: null,
  businessHours: [],
  plans: null,
  appInterface: null,
  cgu: EditorState.createEmpty(),
};

export const settingsSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {
    ...settingsActions,
  },
  extraReducers(builder) {
    settingsAsyncActions(builder);
  },
});

export const settingsSliceActions = settingsSlice.actions;

export default settingsSlice.reducer;
