import { ActionReducerMapBuilder } from "@reduxjs/toolkit";

import { IImamSpeakersInitialState } from "./imamSpeakers.interfaces";

import {
  getImamsSpeakersAsync,
  createImamsSpeakerAsync,
  updateImamsSpeakerAsync,
  deleteImamsSpeakerAsync,
} from "./imamSpeakers.services";

const asyncActions = (
  builder: ActionReducerMapBuilder<IImamSpeakersInitialState>
) => {
  builder.addCase(getImamsSpeakersAsync.fulfilled, (state, action) => {
    state.listImamSpeakers = action.payload;
  });

  builder.addCase(createImamsSpeakerAsync.fulfilled, (state, action) => {
    if (state.listImamSpeakers) {
      state.listImamSpeakers.items.push(action.payload.data);
      state.listImamSpeakers.count++;
    }
  });

  builder.addCase(updateImamsSpeakerAsync.fulfilled, (state, action) => {
    if (state.listImamSpeakers) {
      const index = state.listImamSpeakers.items.findIndex(
        (listImamSpeaker) => listImamSpeaker.id === action.payload.id
      );

      state.listImamSpeakers.items[index] = {
        ...state.listImamSpeakers.items[index],
        ...action.payload,
      };
    }
  });

  builder.addCase(deleteImamsSpeakerAsync.fulfilled, (state, action) => {
    if (state.listImamSpeakers) {
      const index = state.listImamSpeakers.items.findIndex(
        (listImamSpeaker) => listImamSpeaker.id === action.meta.arg
      );

      state.listImamSpeakers.items.splice(index, 1);
      state.listImamSpeakers.count++;
    }
  });
};

export default asyncActions;
