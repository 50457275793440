import { createGlobalStyle, DefaultTheme } from "styled-components";
import convertThemeColorsToRootColors, {
  TThemeColors,
} from "../utils/convertThemeColorsToRootColors";

const GloablStyle = createGlobalStyle`
    *, ::after, ::before {
        box-sizing: border-box;
        font-family: 'Lato', sans-serif;
        padding: 0;
        margin: 0;
    }

    body {
        min-height: 100vh;
        overflow-x: hidden;
        background-color: var(--ui-secondary);
    }

    h1{
        font-family: 'Playfair Display', sans-serif;
        font-weight: normal ;
    }
    h2{
        font-family: 'Playfair Display SC', sans-serif;
        font-weight: 400 ;
    }
    h3{
        font-family: 'Lato', sans-serif;
        font-weight: normal;
    }
    h4{
        font-family: 'Lato', sans-serif;
        font-weight: 200;
    }
    h5{
        font-family: 'Lato', sans-serif;
        font-weight: 400;
    }

    p,a,span{
        font-family: 'Lato', sans-serif;
    }
    
    small{
        font-family: 'Lato', sans-serif;
        font-weight: 200;
    }

    :root {
        --ui-primary-rgb: 115,16,62;
        --ui-secondary-rgb: 254,248,236;
        --ui-tertiary-rgb: 222,184,107;
        --ui-dark-rgb: 37,37,37;
        --ui-medium-rgb: 54,56,83;
        --ui-light-rgb: 249,249,249;
        --ui-black-rgb: 0,0,0;
        --ui-white-rgb: 255,255,255;
        --ui-success-rgb: 117,216,115;
        --ui-error-rgb: 135,45,45;
        --ui-gray-rgb: 135, 144, 161;
        --ui-warning-rgb: 245, 181, 68;

        --title-size: 1.4rem;
        --subtitle-size: 1.1rem;
        --normal-size: 0.9rem;
        --small-size: 0.8rem;
        
        ${({ theme }: { theme: DefaultTheme & { colors: TThemeColors } }) => {
          return convertThemeColorsToRootColors(theme.colors);
        }}
    }

    a {
        text-decoration: none;
    }

    img {
        max-width: 100%;
        height: auto;
    }


    .form-row {
        display: flex;
        flex-wrap: wrap;
    }

    .table-responsive {
        display: block;
        width: 100%;
        overflow-x: auto;
    }

    .MuiDataGrid-main {
        .bg-blue,
        .bg-pink {
            background-color: #687c9e;
            color: var(--ui-secondary);
        }

        .bg-pink {
            background-color: #e85b81;
        }

        .table-badges {
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 9px;
        }

        .table-badge {
            border: 1.5px solid var(--ui-medium);
            width: 41px;
            height: 41px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;

            &.cursor-pointer {
                cursor: pointer;   
            }

            &.table-badge--checked {
                color: rgba(75, 146, 73, 1);
                border: 1.5px solid rgba(75, 146, 73, 1);
            }

            &.table-badge--unchecked {
                color: rgba(109, 15, 50, 1);
                border: 1.5px solid rgba(109, 15, 50, 1);
            }

            &.table-badge--nohandshake {
                opacity: 0.1;
            }

            svg {
                font-size: 2rem;
            }
        }

        .table-actions {
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 6px;

            @media screen and (max-width: 375px) {
                flex-direction: column;
            }

            button {
                cursor: pointer;
                border-radius: 15px;
                background: var(--ui-tertiary);
                color: #fff;
                padding: 0;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 34px;
                height: 34px;
                border: none;

                &:hover {
                    background: #b69657;
                }

                svg {
                    font-size: 1.3rem;
                }
            }
        }
    }

    .zawaj-table {
        width: 100%;
        border: 3px solid var(--ui-tertiary);
        border-radius: 30px;
        border-collapse: separate;
        border-spacing: 0;
        caption-side: bottom;
        overflow: hidden;

        .bg-blue,
        .bg-pink {
            background-color: #687c9e;
            color: var(--ui-secondary);
        }

        .bg-pink {
            background-color: #e85b81;
        }

        @media screen and (max-width: 768px) {
            .sm-none {
                display: none;
            }
        }

        @media screen and (max-width: 425px) {
            .xs-none {
                display: none;
            }
        }

        thead tr > th {
            text-align: center;
            padding: 1.5rem 0.5rem;
            background-color: var(--ui-primary);
            font-weight: 600;
            font-size: 20px;
            color: var(--ui-secondary);
            //border: 1.8px solid var(--ui-tertiary);

            span {
            font-weight: 600;
            font-size: 12px;
            line-height: 14px;
            color: var(--ui-tertiary);
            }
        }

        tbody tr > td {
            border: 1.8px solid var(--ui-tertiary);
            font-weight: 400;
            font-size: 20px;
            padding: 0.5rem;

            & > p {
                font-weight: 400;
                line-height: 24px;

                &:first-child {
                    font-size: 20px;
                    color: var(--ui-medium);
                    margin-bottom: 0.5rem;
                }

                &:last-child {
                    font-size: 18px;
                    color: var(--ui-primary);
                }
            }

            .table-badges {
                display: flex;
                justify-content: space-between;
                align-items: center;
                gap: 9px;
            }

            .table-badge {
                border: 1.5px solid var(--ui-medium);
                width: 41px;
                height: 41px;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 50%;

                &.table-badge--checked {
                    color: rgba(75, 146, 73, 1);
                    border: 1.5px solid rgba(75, 146, 73, 1);
                }

                &.table-badge--unchecked {
                    color: rgba(109, 15, 50, 1);
                    border: 1.5px solid rgba(109, 15, 50, 1);
                }

                &.table-badge--nohandshake {
                    opacity: 0.1;
                    
                }

                svg {
                    font-size: 2rem;
                }
            }

            .table-actions {
                display: flex;
                justify-content: space-between;
                align-items: center;
                gap: 6px;

                @media screen and (max-width: 375px) {
                    flex-direction: column;
                }

                button {
                    cursor: pointer;
                    border-radius: 15px;
                    background: var(--ui-tertiary);
                    color: #fff;
                    padding: 0;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 34px;
                    height: 34px;
                    border: none;

                    &:hover {
                        background: #b69657;
                    }

                    svg {
                        font-size: 1.3rem;
                    }
                }
            }
        }
    }

    .swal2-container {
        z-index: 20000 !important;

        .swal2-title {
            font-family: 'Lato', sans-serif;
        }
    }

`;

export default GloablStyle;
