import { axios } from "@/lib/axios";
import { TConversation, TMessage } from "../../types";

import {
  IAPIGetCancellationRequests,
  IAPIGetConversations,
  IAPIGetConversationsByMemberId,
  IAPIGetMessagesOfConversation,
  IAPIGetNotModeratedMessages,
  IAPIGetWaitingMessages,
  IAPIPatchConversation,
} from "../../types/interfaces/api/conversations";

export const getListExchanges = ({
  status = "any",
  phone_email,
  limit,
  page,
}: {
  phone_email?: string;
  status?: "open" | "closed" | "any";
  limit?: number;
  page?: number;
} = {}): Promise<IAPIGetConversations> => {
  return axios.get("/conversations", {
    params: {
      phone_email,
      status,
      limit,
      page,
    },
  });
};

export const closeExchange = ({
  conversationId,
  note,
}: {
  conversationId: string;
  note?: string;
}): Promise<IAPIPatchConversation> => {
  return axios.patch(`/conversations/close-by-admin/${conversationId}`, {
    note,
  });
};

export const getWaitingMessages = ({
  text,
  limit,
  page,
}: {
  text?: string;
  limit?: number;
  page?: number;
} = {}): Promise<IAPIGetWaitingMessages> => {
  return axios.get(`/conversations/not/moderated/messages`, {
    params: {
      limit,
      page,
      text,
    },
  });
};

export const getConversationsByMemberId = (
  idMember: string,
  {
    limit,
    page,
  }: {
    limit?: number;
    page?: number;
  } = {}
): Promise<IAPIGetConversationsByMemberId> => {
  return axios.get(`/conversations/all/by/${idMember}`, {
    params: {
      limit,
      page,
    },
  });
};

export const getMessagesOfConversation = (
  idConversation: string,
  {
    limit,
    page,
  }: {
    limit?: number;
    page?: number;
  } = {}
): Promise<IAPIGetMessagesOfConversation> => {
  return axios.get(`/conversations/${idConversation}/messages`, {
    params: {
      limit,
      page,
    },
  });
};

export const getNotModeratedMessages = ({
  text,
  limit,
  page,
}: {
  text?: string;
  limit?: number;
  page?: number;
} = {}): Promise<IAPIGetNotModeratedMessages> => {
  return axios.get(`/conversations/not/moderated/messages`, {
    params: {
      text,
      limit,
      page,
    },
  });
};

export const getCancellationRequest = ({
  limit,
  page,
}: {
  limit?: number;
  page?: number;
} = {}): Promise<IAPIGetCancellationRequests> => {
  return axios.get(`/conversations/cancellation/requests`, {
    params: {
      limit,
      page,
    },
  });
};

export const getConversationById = (
  conversationId: string
): Promise<TConversation> => {
  return axios.get(`/conversations/${conversationId}`);
};

export const addNoteToConversation = (
  conversationId: string,
  message: string
): Promise<{
  conversation_id: string;
  id: string;
  message: string;
  sent_by_admin: boolean;
  status: "validated" | "pending" | "rejected";
  is_delivered: boolean;
  is_read: boolean;
  is_moderated: boolean;
  date: string;
}> => {
  return axios.post(`/conversations/${conversationId}/messages`, {
    message,
  });
};

export const moderateMessage = ({
  conversation_id,
  message_id,
  note = "",
  message = "",
  validate,
  operation = "message-validation",
}: {
  conversation_id: string;
  message_id: string;
  note?: string;
  message?: string;
  validate: boolean;
  operation?: "message-validation" | "message-to-sender";
}): Promise<TMessage> => {
  return axios.patch(
    `/conversations/${conversation_id}/messages/${message_id}`,
    {
      operation,
      conversation_id,
      message_id,
      validate,
      note,
      message,
    }
  );
};
