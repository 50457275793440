import { axios } from "@/lib/axios";
import {
  IAPIPatchEthnicities,
  IAPIPostEthnicities,
  IAPIResponseGetEthnicities,
} from "../../types";

export const getEthnicities = (): Promise<IAPIResponseGetEthnicities> => {
  return axios.get("/ethnicities");
};

export const createEthnicity = ({
  name,
}: {
  name: string;
}): Promise<IAPIPostEthnicities> => {
  return axios.post("/ethnicities", {
    name,
  });
};

export const updateEthnicity = ({
  id,
  name,
}: IAPIPatchEthnicities): Promise<{
  id: string;
  name: string;
}> => {
  return axios.patch(`/ethnicities/${id}`, {
    name,
  });
};
