import { axios } from "@/lib/axios";
import { TImamSpeakerInitialValues } from "../../containers/TeamManagement/ImamSpeakers/ImamSpeakers.container";

import {
  IAPIDeleteImamsSpeakers,
  IAPIGetImamsSpeakers,
  IAPIPatchImamsSpeakers,
  IAPIPostImamsSpeakers,
} from "../../types";

export const getImamsSpeakers = (): Promise<IAPIGetImamsSpeakers> => {
  return axios.get("/imams-speakers");
};

export const createImamsSpeaker = (
  datas: TImamSpeakerInitialValues
): Promise<IAPIPostImamsSpeakers> => {
  return axios.post("/imams-speakers", {
    ...datas,
  });
};

export const updateImamsSpeaker = (
  id: string,
  datas: TImamSpeakerInitialValues
): Promise<IAPIPatchImamsSpeakers> => {
  return axios.patch(`/imams-speakers/${id}`, {
    ...datas,
  });
};

export const deleteImamsSpeaker = (
  id: string
): Promise<IAPIDeleteImamsSpeakers> => {
  return axios.delete(`/imams-speakers/${id}`);
};
