import { ActionReducerMapBuilder } from "@reduxjs/toolkit";

import { IAdminsInitialState } from "./admin.interfaces";

import {
  getListStaffs,
  addStaffAsync,
  updateStaffAsync,
  deleteStaffAsync,
} from "./admin.services";

import { errorInitialValue } from "./admin.slice";

const adminAsyncActions = (
  builder: ActionReducerMapBuilder<IAdminsInitialState>
) => {
  builder.addCase(getListStaffs.pending, (state) => {
    state.loading = "pending";
    state.errors = errorInitialValue;
  });

  builder.addCase(getListStaffs.fulfilled, (state, action) => {
    state.loading = "idle";
    state.errors = errorInitialValue;
    state.listAdmins = action.payload;
  });

  builder.addCase(getListStaffs.rejected, (state, action) => {
    state.loading = "failed";
    state.errors = action.payload;
  });

  builder.addCase(addStaffAsync.fulfilled, (state, action) => {
    state.loading = "idle";
    state.errors = errorInitialValue;

    if (state.listAdmins) {
      state.listAdmins.data.push(action.payload.data);
      state.listAdmins.count++;
    }
  });

  builder.addCase(updateStaffAsync.fulfilled, (state, action) => {
    state.loading = "idle";
    state.errors = errorInitialValue;

    if (state.listAdmins) {
      const index = state.listAdmins.data.findIndex(
        (admin) => admin.id === action.payload.id
      );

      state.listAdmins.data[index] = {
        ...state.listAdmins.data[index],
        ...action.payload,
      };
    }
  });

  builder.addCase(deleteStaffAsync.fulfilled, (state, action) => {
    state.loading = "idle";
    state.errors = errorInitialValue;

    if (state.listAdmins) {
      const index = state.listAdmins.data.findIndex(
        (admin) => admin.id === action.meta.arg
      );

      state.listAdmins.data.splice(index, 1);
      state.listAdmins.count++;
    }
  });
};

export default adminAsyncActions;
