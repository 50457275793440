import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import to from "await-to-js";

import {
  deleteAModerationTemplate,
  getAModerationTemplate,
  getModerationTemplates,
  patchModerationTemplate,
  postModerationTemplate,
} from "@/features/backoffice/api";

import {
  IAPIResponseGetModerationTemplate,
  IAPIResponsePostModerationTemplate,
} from "@/features/backoffice/types";

import { TReducerError } from "@/types/app";

import handlingError from "@/utils/handlingError";

export const getListModerationTemplates = createAsyncThunk<
  IAPIResponseGetModerationTemplate,
  undefined,
  {
    rejectValue: TReducerError;
  }
>("moderation/getModerationTemplates", async (_param, { rejectWithValue }) => {
  const [error, data] = await to<
    IAPIResponseGetModerationTemplate,
    AxiosError<any>
  >(getModerationTemplates());

  if (error) {
    return rejectWithValue(handlingError(error));
  }

  return data;
});

export const createModerationTemplate = createAsyncThunk<
  IAPIResponsePostModerationTemplate,
  string,
  {
    rejectValue: TReducerError;
  }
>("moderation/postModerationTemplates", async (body, { rejectWithValue }) => {
  const [error, data] = await to<
    IAPIResponsePostModerationTemplate,
    AxiosError<any>
  >(postModerationTemplate(body));

  if (error) {
    return rejectWithValue(handlingError(error));
  }

  return data;
});

type TUpdateModerationTemplateParams = {
  id: string;
  body: string;
};

export const updateModerationTemplate = createAsyncThunk<
  { id: string; body: string },
  TUpdateModerationTemplateParams,
  {
    rejectValue: TReducerError;
  }
>(
  "moderation/updateModerationTemplates",
  async ({ id, body }, { rejectWithValue }) => {
    const [error, data] = await to<
      TUpdateModerationTemplateParams,
      AxiosError<any>
    >(patchModerationTemplate(id, body));

    if (error) {
      return rejectWithValue(handlingError(error));
    }

    return data;
  }
);

export const getOneModerationTemplate = createAsyncThunk<
  { id: string; body: string },
  string,
  {
    rejectValue: TReducerError;
  }
>("moderation/getOneModerationTemplates", async (id, { rejectWithValue }) => {
  const [error, data] = await to<{ id: string; body: string }, AxiosError<any>>(
    getAModerationTemplate(id)
  );

  if (error) {
    return rejectWithValue(handlingError(error));
  }

  return data;
});

export const deleteModerationTemplate = createAsyncThunk<
  { code: string; message: string },
  string,
  {
    rejectValue: TReducerError;
  }
>("moderation/deleteModerationTemplate", async (id, { rejectWithValue }) => {
  const [error, data] = await to<
    { code: string; message: string },
    AxiosError<any>
  >(deleteAModerationTemplate(id));

  if (error) {
    return rejectWithValue(handlingError(error));
  }

  return data;
});
