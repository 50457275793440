import { axios } from "@/lib/axios";

import {
  IAPIResponseGetModerationTemplate,
  IAPIResponsePostModerationTemplate,
} from "../../types";

export const getModerationTemplates =
  (): Promise<IAPIResponseGetModerationTemplate> => {
    return axios.get("/moderation-templates");
  };

export const postModerationTemplate = (
  body: string
): Promise<IAPIResponsePostModerationTemplate> => {
  return axios.post("/moderation-templates", {
    body,
  });
};

export const patchModerationTemplate = (
  id: string,
  body: string
): Promise<{ id: string; body: string }> => {
  return axios.patch(`/moderation-templates/${id}`, {
    body,
  });
};

export const getAModerationTemplate = (
  id: string
): Promise<{ id: string; body: string }> => {
  return axios.get(`/moderation-templates/${id}`);
};

export const deleteAModerationTemplate = (
  id: string
): Promise<{ code: string; message: string }> => {
  return axios.delete(`/moderation-templates/${id}`);
};
