import { axios } from "@/lib/axios";

import {
  IAPIGetCommunicationSMS,
  IAPIPatchPublishCommunicationSMS,
  IAPIPatchUpdateCommunicationSMS,
  IAPIPostCommunicationSMS,
} from "../../types";

export const getCommunicationSMS = ({
  limit,
  page,
}: {
  limit?: number;
  page?: number;
} = {}): Promise<IAPIGetCommunicationSMS> => {
  return axios.get(`/news-messages`, {
    params: {
      limit,
      page,
    },
  });
};

export const createCommunicationSMS = ({
  ID,
  audience,
  title,
  message,
}: {
  audience: "all" | "male" | "female" | "one";
  title: string;
  message: string;
  ID?: string;
}): Promise<IAPIPostCommunicationSMS> => {
  return axios.post(`/news-messages`, {
    audience,
    title,
    message,
    id: ID,
  });
};

export const editCommunicationSMS = ({
  communicationSMSId,
  audience,
  title,
  message,
  ID,
}: {
  communicationSMSId: string;
  audience: "all" | "male" | "female" | "one";
  title: string;
  message: string;
  ID?: string;
}): Promise<IAPIPatchUpdateCommunicationSMS> => {
  return axios.patch(`/news-messages/${communicationSMSId}`, {
    audience,
    title,
    message,
    ID,
  });
};

export const publishCommunicationSMS = (
  communicationSMSId: string
): Promise<IAPIPatchPublishCommunicationSMS> => {
  return axios.patch(`/news-messages/${communicationSMSId}/send`);
};

export const deleteCommunicationSMS = (
  communicationSMSId: string
): Promise<{
  code: string;
  message: string;
}> => {
  return axios.delete(`/news-messages/${communicationSMSId}`);
};
