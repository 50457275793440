import { axios } from "@/lib/axios";
import { TAdminInitialValues } from "../../pages/staff/Team.page";

import {
  IAPIDeleteResponseDeleteStaff,
  IAPIGetAdminStatsMessages,
  IAPIGetAdminStatsProfiles,
  IAPIGetAdminStatsResponse,
  IAPIPatchResponseUpdateStaff,
  IAPIPostResponseAddStaff,
  IAPIResponseGetAllStaffs,
} from "../../types";

export const getAllStaffs = (): Promise<IAPIResponseGetAllStaffs> => {
  return axios.get("/admins");
};

export const getAdminStatsDashboard = (datas: {
  adminId: string;
  start_date?: string;
  end_date?: string;
}): Promise<IAPIGetAdminStatsResponse> => {
  return axios.get(`/admins/${datas.adminId}/admins-stats/dashboard`, {
    params: {
      start_date: datas.start_date,
      end_date: datas.end_date,
    },
  });
};

export const getAdminStatsProfiles = (datas: {
  adminId: string;
  page?: number;
  limit?: number;
  filter?: "accepted" | "rejected";
  start_date?: string;
  end_date?: string;
  search?: string;
}): Promise<IAPIGetAdminStatsProfiles> => {
  return axios.get(`/admins/${datas.adminId}/admins-stats/profiles`, {
    params: {
      page: datas.page,
      limit: datas.limit,
      filter: datas.filter,
      start_date: datas.start_date,
      end_date: datas.end_date,
      search: datas.search,
    },
  });
};

export const getAdminStatsMessages = (datas: {
  adminId: string;
  page?: number;
  limit?: number;
  filter?: "accepted" | "rejected";
  start_date?: string;
  end_date?: string;
  search?: string;
}): Promise<IAPIGetAdminStatsMessages> => {
  return axios.get(`/admins/${datas.adminId}/admins-stats/messages`, {
    params: {
      page: datas.page,
      limit: datas.limit,
      filter: datas.filter,
      start_date: datas.start_date,
      end_date: datas.end_date,
      search: datas.search,
    },
  });
};

export const addStaff = (
  data: TAdminInitialValues
): Promise<IAPIPostResponseAddStaff> => {
  return axios.post("/admins", {
    ...data,
  });
};

export const updateStaff = (
  id: string,
  data: TAdminInitialValues
): Promise<IAPIPatchResponseUpdateStaff> => {
  return axios.patch(`/admins/${id}/account/infos`, {
    ...data,
  });
};

export const deleteStaff = (
  id: string
): Promise<IAPIDeleteResponseDeleteStaff> => {
  return axios.delete(`/admins/${id}`);
};

export const updateAdminPassword = ({
  current_password,
  new_password,
}: {
  current_password: string;
  new_password: string;
}): Promise<{
  code: string;
  message: string;
}> => {
  return axios.patch(`/admins/auth/password`, {
    current_password,
    new_password,
  });
};

export const updateModeratorPassword = ({
  id,
  password,
}: {
  id: string;
  password: string;
}): Promise<{
  code: string;
  message: string;
}> => {
  return axios.patch(`/admins/auth/${id}/password`, {
    password,
  });
};
