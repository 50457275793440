import { createContext, useEffect, useState } from "react";
import { useLocation, useRoutes } from "react-router-dom";
import { createStructuredSelector } from "reselect";
import { connect, ConnectedProps } from "react-redux";

import socketIOClient, { Socket } from "socket.io-client";

import {
  selectAuthToken,
  selectIsAuthenticated,
} from "@/store/reducers/auth/auth.selector";

import { publicRoutes } from "./public.routes";

import { protectedRoutes } from "./protected.routes";

import { setAuthToken } from "@/utils/setAuthToken";
import { SOCKET_URL } from "@/config";
import { selectAvailability } from "@/store/reducers/app/app.selector";
import { getAppSettingsAsync } from "@/store/reducers/settings/settings.services";
import { Toastify } from "@/lib/toast";
import { useAppDispatch } from "@/store";

type PropsFromRedux = ConnectedProps<typeof connector>;

export const AppContext = createContext<{ socketIO: Socket | null }>({
  socketIO: null,
});

const AppRoutes: React.FC<PropsFromRedux> = ({
  isAuthenticated,
  userToken,
  isAvailable,
}) => {
  const dispatch = useAppDispatch();
  const location = useLocation();

  const [isConnected, setIsConnected] = useState(isAuthenticated);
  const [socketIO, setSocketIO] = useState<Socket | null>(null);

  useEffect(() => {
    setIsConnected(isAuthenticated);

    if (userToken) {
      const io = socketIOClient(SOCKET_URL, {
        path: "/socket/v1/socketio/",
        query: { token: userToken },
      });

      io.on("connect_error", () => {
        setTimeout(() => {
          io.connect();
        }, 1000);
      });

      setSocketIO(io);

      if (location.pathname !== "/parameters/interface") getAppSettings();
    }

    return () => {
      if (!isAuthenticated && !userToken && socketIO) {
        socketIO.disconnect();
      }
    };
  }, [isAuthenticated, userToken]);

  useEffect(() => {
    if (socketIO) {
      if (isAvailable && !socketIO.connected) {
        socketIO.connect();
      } else if (!isAvailable && socketIO.connected) {
        socketIO.disconnect();
      }
    }
  }, [isAvailable, socketIO]);

  const getAppSettings = async () => {
    const res = await dispatch(getAppSettingsAsync());

    if (getAppSettingsAsync.rejected.match(res)) {
      if (res.payload) {
        Toastify(`${res.payload.message}`, "error", 3000);
      } else {
        Toastify(`${res.error.message}`, "error", 3000);
      }
    }
  };

  setAuthToken(userToken);

  const routes = isConnected ? protectedRoutes : publicRoutes;

  const element = useRoutes(routes);

  return (
    <AppContext.Provider value={{ socketIO }}>{element}</AppContext.Provider>
  );
};

const mapStateToProps = createStructuredSelector({
  isAuthenticated: selectIsAuthenticated,
  userToken: selectAuthToken,
  isAvailable: selectAvailability,
});

const connector = connect(mapStateToProps);

export default connector(AppRoutes);
