import { TReducerError } from "@/types/app";
import { createSlice } from "@reduxjs/toolkit";

import { IModerationInitialState } from "./moderation.interfaces";

import * as moderateActions from "./moderation.actions";

import moderationAsyncActions from "./moderation.asyncActions";

export const errorInitialValue: TReducerError = {
  message: "",
  value: null,
};

const initialState: IModerationInitialState = {
  moderationTemplates: null,
  loading: "idle",
  errors: {
    message: "",
    value: null,
  },
};

export const moderationSlice = createSlice({
  name: "moderation",
  initialState,
  reducers: {
    ...moderateActions,
  },
  extraReducers(builder) {
    moderationAsyncActions(builder);
  },
});

export const moderationSliceActions = moderationSlice.actions;

export default moderationSlice.reducer;
