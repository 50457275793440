import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import to from "await-to-js";

import { TReducerError } from "@/types/app";

import {
  IAPIGetDeletedProfiles,
  IAPIGetMembersDatas,
  IAPIGetProfileValidations,
  IAPIGetReports,
  IAPIGetSubscriptions,
  IAPIPatchReportMessage,
  IAPIPostMember,
  IAPIResponseGetAllProfiles,
  TInfosHealthPayload,
  TInfosProfilePayload,
  TInfosReligiousAspectPayload,
  TInfosReligiousPracticePayload,
  TMaritalStatus,
  TMemberProfile,
  TPagination,
  TProfilesFilter,
  TReportsFilter,
} from "@/features/backoffice/types";

import handlingError from "@/utils/handlingError";

import {
  addMemberAccount,
  approuveAccount,
  banProfile,
  switchProfileToFree,
  deleteAccount,
  disApprouveAccount,
  getAllProfiles,
  getAllReports,
  getAllSubscriptions,
  getDeletedProfiles,
  getFilteredProfiles,
  getFilteredReports,
  getMemberDetails,
  getMembersDatas,
  getPendingProfiles,
  getProfileMember,
  getProfileValidations,
  resetMemberPassword,
  restoreMemberAccount,
  sendReportMessage,
  toggleAccountActiveness,
  unbanProfile,
  editMemberAccount,
  updateMemberProfile,
  updateMemberReligousAspect,
  updateMemberReligousPractice,
  updateMemberHealth,
} from "@/features/backoffice/api";
import { TMemberInitialValues } from "@/features/backoffice/pages/members/ProfileManagement.page";

export const getMemberDetailsAsync = createAsyncThunk<
  TMemberProfile,
  string,
  {
    rejectValue: TReducerError;
  }
>("members/getMemberDetails", async (memberId, { rejectWithValue }) => {
  const [error, data] = await to<TMemberProfile, AxiosError<any>>(
    getMemberDetails(memberId)
  );

  if (error) {
    return rejectWithValue(handlingError(error));
  }

  return data;
});

export const getListAccountsMember = createAsyncThunk<
  IAPIResponseGetAllProfiles,
  (TPagination & { search?: string }) | undefined,
  {
    rejectValue: TReducerError;
  }
>("members/getAllProfiles", async (pagination, { rejectWithValue }) => {
  const [error, data] = await to<IAPIResponseGetAllProfiles, AxiosError<any>>(
    getAllProfiles({ ...pagination })
  );

  if (error) {
    return rejectWithValue(handlingError(error));
  }

  return data;
});

export const getFilteredProfilesAsync = createAsyncThunk<
  IAPIResponseGetAllProfiles,
  (TProfilesFilter & TPagination) | undefined,
  {
    rejectValue: TReducerError;
  }
>("members/getFilteredProfiles", async (filters, { rejectWithValue }) => {
  const [error, data] = await to<IAPIResponseGetAllProfiles, AxiosError<any>>(
    getFilteredProfiles({ ...filters })
  );

  if (error) {
    return rejectWithValue(handlingError(error));
  }

  return data;
});

export const getListPendingAccountsMember = createAsyncThunk<
  IAPIResponseGetAllProfiles,
  undefined,
  {
    rejectValue: TReducerError;
  }
>("members/getPendingProfiles", async (_param, { rejectWithValue }) => {
  const [error, data] = await to<IAPIResponseGetAllProfiles, AxiosError<any>>(
    getPendingProfiles()
  );

  if (error) {
    return rejectWithValue(handlingError(error));
  }

  return data;
});

export const getListDeletedAccountsMember = createAsyncThunk<
  IAPIGetDeletedProfiles,
  ({ phone_email: string | undefined } & TPagination) | undefined,
  {
    rejectValue: TReducerError;
  }
>("members/getDeletedProfiles", async (pagination, { rejectWithValue }) => {
  const [error, data] = await to<IAPIGetDeletedProfiles, AxiosError<any>>(
    getDeletedProfiles(pagination)
  );

  if (error) {
    return rejectWithValue(handlingError(error));
  }

  return data;
});

export const approuveAccountAsync = createAsyncThunk<
  TMemberProfile,
  string,
  {
    rejectValue: TReducerError;
  }
>("members/approuveAccount", async (id, { rejectWithValue }) => {
  const [error, data] = await to<TMemberProfile, AxiosError<any>>(
    approuveAccount(id)
  );

  if (error) {
    return rejectWithValue(handlingError(error));
  }

  return data;
});

export const disApprouveAccountAsync = createAsyncThunk<
  TMemberProfile,
  { id: string; message: string },
  {
    rejectValue: TReducerError;
  }
>("members/disApprouveAccount", async (datas, { rejectWithValue }) => {
  const [error, data] = await to<TMemberProfile, AxiosError<any>>(
    disApprouveAccount(datas.id, datas.message)
  );

  if (error) {
    return rejectWithValue(handlingError(error));
  }

  return data;
});

export const deleteAccountAsync = createAsyncThunk<
  {
    code: string;
    message: string;
  },
  { id: string; message: string; password: string },
  {
    rejectValue: TReducerError;
  }
>("members/deleteAccount", async (datas, { rejectWithValue }) => {
  const [error, data] = await to<
    {
      code: string;
      message: string;
    },
    AxiosError<any>
  >(deleteAccount(datas.id, datas.password, datas.message));

  if (error) {
    return rejectWithValue(handlingError(error));
  }

  return data;
});

export const toggleAccountActivenessAsync = createAsyncThunk<
  {
    code: string;
    message: string;
  },
  { id: string; reason?: string },
  {
    rejectValue: TReducerError;
  }
>("members/toggleAccountActiveness", async (params, { rejectWithValue }) => {
  const [error, data] = await to<
    {
      code: string;
      message: string;
    },
    AxiosError<any>
  >(toggleAccountActiveness(params.id, params.reason));

  if (error) {
    return rejectWithValue(handlingError(error));
  }

  return data;
});

export const banProfileAsync = createAsyncThunk<
  {
    code: string;
    message: string;
  },
  { id: string; reason?: string },
  {
    rejectValue: TReducerError;
  }
>("members/ban", async (params, { rejectWithValue }) => {
  const [error, data] = await to<
    {
      code: string;
      message: string;
    },
    AxiosError<any>
  >(banProfile(params.id, params.reason));

  if (error) {
    return rejectWithValue(handlingError(error));
  }

  return data;
});

export const unbanProfileAsync = createAsyncThunk<
  {
    code: string;
    message: string;
  },
  string,
  {
    rejectValue: TReducerError;
  }
>("members/unban", async (id, { rejectWithValue }) => {
  const [error, data] = await to<
    {
      code: string;
      message: string;
    },
    AxiosError<any>
  >(unbanProfile(id));

  if (error) {
    return rejectWithValue(handlingError(error));
  }

  return data;
});

export const switchProfileToFreeAsync = createAsyncThunk<
  TMemberProfile,
  { id: string; free: boolean },
  {
    rejectValue: TReducerError;
  }
>("members/switch-account-to-free", async (params, { rejectWithValue }) => {
  const [error, data] = await to<TMemberProfile, AxiosError<any>>(
    switchProfileToFree(params.id, params.free)
  );

  if (error) {
    return rejectWithValue(handlingError(error));
  }

  return data;
});

export const getAllReportsAsync = createAsyncThunk<
  IAPIGetReports,
  TPagination | undefined,
  {
    rejectValue: TReducerError;
  }
>("members/getAllReports", async (filters, { rejectWithValue }) => {
  const [error, data] = await to<IAPIGetReports, AxiosError<any>>(
    getAllReports(filters)
  );

  if (error) {
    return rejectWithValue(handlingError(error));
  }

  return data;
});

export const getFilteredReportsAsync = createAsyncThunk<
  IAPIGetReports,
  (TReportsFilter & TPagination) | undefined,
  {
    rejectValue: TReducerError;
  }
>("members/getFilteredReports", async (filters, { rejectWithValue }) => {
  const [error, data] = await to<IAPIGetReports, AxiosError<any>>(
    getFilteredReports(filters)
  );

  if (error) {
    return rejectWithValue(handlingError(error));
  }

  return data;
});

export const getListSubscriptionsAsync = createAsyncThunk<
  IAPIGetSubscriptions,
  ({ phone_email: string | undefined } & TPagination) | undefined,
  {
    rejectValue: TReducerError;
  }
>("members/getAllSubscriptions", async (pagination, { rejectWithValue }) => {
  const [error, data] = await to<IAPIGetSubscriptions, AxiosError<any>>(
    getAllSubscriptions(pagination)
  );

  if (error) {
    return rejectWithValue(handlingError(error));
  }

  return data;
});

export const sendReportMessageAsync = createAsyncThunk<
  IAPIPatchReportMessage,
  {
    reportId: string;
    receiver: string;
    subject: string;
    message: string;
  },
  {
    rejectValue: TReducerError;
  }
>("members/sendReportMessage", async (datas, { rejectWithValue }) => {
  const [error, data] = await to<IAPIPatchReportMessage, AxiosError<any>>(
    sendReportMessage({ ...datas })
  );

  if (error) {
    return rejectWithValue(handlingError(error));
  }

  return data;
});

export const getMembersDatasAsync = createAsyncThunk<
  IAPIGetMembersDatas,
  | {
      country?: string;
      citizenship?: string;
      home_country?: string;
      marital_status?: TMaritalStatus;
      gender?: "male" | "female";
      max_age?: number;
      min_age?: number;
    }
  | TPagination
  | undefined,
  {
    rejectValue: TReducerError;
  }
>("members/getMembersDatas", async (filters, { rejectWithValue }) => {
  const [error, data] = await to<IAPIGetMembersDatas, AxiosError<any>>(
    getMembersDatas(filters)
  );

  if (error) {
    return rejectWithValue(handlingError(error));
  }

  return data;
});

export const resetMemberPasswordAsync = createAsyncThunk<
  TMemberProfile,
  string,
  {
    rejectValue: TReducerError;
  }
>("members/resetMemberPassword", async (memberId, { rejectWithValue }) => {
  const [error, data] = await to<TMemberProfile, AxiosError<any>>(
    resetMemberPassword(memberId)
  );

  if (error) {
    return rejectWithValue(handlingError(error));
  }

  return data;
});

export const restoreMemberAccountAsync = createAsyncThunk<
  TMemberProfile,
  string,
  {
    rejectValue: TReducerError;
  }
>("members/restoreMemberAccount", async (memberId, { rejectWithValue }) => {
  const [error, data] = await to<TMemberProfile, AxiosError<any>>(
    restoreMemberAccount(memberId)
  );

  if (error) {
    return rejectWithValue(handlingError(error));
  }

  return data;
});

export const getProfileMemberAsync = createAsyncThunk<
  TMemberProfile,
  string,
  {
    rejectValue: TReducerError;
  }
>("members/getProfileMember", async (memberId, { rejectWithValue }) => {
  const [error, data] = await to<TMemberProfile, AxiosError<any>>(
    getProfileMember(memberId)
  );

  if (error) {
    return rejectWithValue(handlingError(error));
  }

  return data;
});

export const addMemberAsync = createAsyncThunk<
  IAPIPostMember,
  TMemberInitialValues,
  {
    rejectValue: TReducerError;
  }
>("members/addMember", async (values, { rejectWithValue }) => {
  const [error, data] = await to<IAPIPostMember, AxiosError<any>>(
    addMemberAccount(values)
  );

  if (error) {
    return rejectWithValue(handlingError(error));
  }

  return data;
});

export const editMemberAsync = createAsyncThunk<
  TMemberProfile,
  TMemberInitialValues,
  {
    rejectValue: TReducerError;
  }
>("members/editMember", async (values, { rejectWithValue }) => {
  const [error, data] = await to<TMemberProfile, AxiosError<any>>(
    editMemberAccount(values)
  );

  if (error) {
    return rejectWithValue(handlingError(error));
  }

  return data;
});

export const getProfileValidationsAsync = createAsyncThunk<
  IAPIGetProfileValidations,
  | {
      admin?: string;
      member?: string;
      status?: boolean;
    }
  | TPagination
  | undefined,
  {
    rejectValue: TReducerError;
  }
>("members/getProfileValidations", async (filters, { rejectWithValue }) => {
  const [error, data] = await to<IAPIGetProfileValidations, AxiosError<any>>(
    getProfileValidations(filters)
  );

  if (error) {
    return rejectWithValue(handlingError(error));
  }

  return data;
});

export const updateMemberInfosProfileAsync = createAsyncThunk<
  TMemberProfile,
  { id: string; data: TInfosProfilePayload },
  {
    rejectValue: TReducerError;
  }
>("members/updateMemberInfosProfile", async (infos, { rejectWithValue }) => {
  const [error, data] = await to<TMemberProfile, AxiosError<any>>(
    updateMemberProfile(infos.id, infos.data)
  );

  if (error) {
    return rejectWithValue(handlingError(error));
  }

  return data;
});

export const updateMemberInfosReligiousAspectAsync = createAsyncThunk<
  TMemberProfile,
  { id: string; gender: "male" | "female"; data: TInfosReligiousAspectPayload },
  {
    rejectValue: TReducerError;
  }
>(
  "members/updateMemberInfosReligousAspect",
  async (infos, { rejectWithValue }) => {
    const [error, data] = await to<TMemberProfile, AxiosError<any>>(
      updateMemberReligousAspect(infos.id, infos.gender, infos.data)
    );

    if (error) {
      return rejectWithValue(handlingError(error));
    }

    return data;
  }
);

export const updateMemberInfosReligiousPracticeAsync = createAsyncThunk<
  TMemberProfile,
  { id: string; data: TInfosReligiousPracticePayload },
  {
    rejectValue: TReducerError;
  }
>(
  "members/updateMemberInfosReligousPractice",
  async (infos, { rejectWithValue }) => {
    const [error, data] = await to<TMemberProfile, AxiosError<any>>(
      updateMemberReligousPractice(infos.id, infos.data)
    );

    if (error) {
      return rejectWithValue(handlingError(error));
    }

    return data;
  }
);

export const updateMemberInfosHealthAsync = createAsyncThunk<
  TMemberProfile,
  { id: string; data: TInfosHealthPayload },
  {
    rejectValue: TReducerError;
  }
>("members/updateMemberInfosHealth", async (infos, { rejectWithValue }) => {
  const [error, data] = await to<TMemberProfile, AxiosError<any>>(
    updateMemberHealth(infos.id, infos.data)
  );

  if (error) {
    return rejectWithValue(handlingError(error));
  }

  return data;
});
