import { IModerationInitialState } from "./moderation.interfaces";
import { PayloadAction } from "@reduxjs/toolkit";

export const deleteModeration = (
  state: IModerationInitialState,
  action: PayloadAction<string>
) => {
  if (state.moderationTemplates) {
    state.moderationTemplates.data = [
      ...state.moderationTemplates.data.filter(
        (data) => data.id !== action.payload
      ),
    ] as [{ id: string; body: string }];
    state.moderationTemplates.count--;
  }
};
