import { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { EditorState, convertFromRaw } from "draft-js";

import { ISettingsInitialState } from "./settings.interfaces";

import {
  getBusinessHoursAsync,
  updateBusinessHoursAsync,
  getEthnicitiesAsync,
  createEthnicitiesAsync,
  updateEthnicitiesAsync,
  getPlansAsync,
  createPlansAsync,
  getAppSettingsAsync,
  initAppSettingsAsync,
  updateAppSettingsAsync,
  getCGUAsync,
  initCGUAsync,
  updateCGUAsync,
  updatePlansAsync,
} from "./settings.services";

const adminAsyncActions = (
  builder: ActionReducerMapBuilder<ISettingsInitialState>
) => {
  builder.addCase(getEthnicitiesAsync.fulfilled, (state, action) => {
    state.ethnicities = action.payload;
  });

  builder.addCase(createEthnicitiesAsync.fulfilled, (state, action) => {
    if (state.ethnicities) {
      state.ethnicities.items.push(action.payload.data);
      state.ethnicities.count++;
    }
  });

  builder.addCase(updateEthnicitiesAsync.fulfilled, (state, action) => {
    if (state.ethnicities) {
      const index = state.ethnicities.items.findIndex(
        (ethnie) => ethnie.id === action.payload.id
      );

      state.ethnicities.items[index] = {
        ...state.ethnicities.items[index],
        ...action.payload,
      };
    }
  });

  builder.addCase(getBusinessHoursAsync.fulfilled, (state, action) => {
    state.businessHours = action.payload;
  });

  builder.addCase(updateBusinessHoursAsync.fulfilled, (state, action) => {
    state.businessHours = action.payload;
  });

  builder.addCase(getPlansAsync.fulfilled, (state, action) => {
    state.plans = action.payload;
  });

  builder.addCase(createPlansAsync.fulfilled, (state, action) => {
    if (state.plans) {
      state.plans.items.push(action.payload.data);
    }
  });

  builder.addCase(updatePlansAsync.fulfilled, (state, action) => {
    if (state.plans) {
      const index = state.plans.items.findIndex(
        (plan) => plan.id === action.payload.id
      );

      state.plans.items[index] = {
        ...state.plans.items[index],
        ...action.payload,
      };
    }
  });

  builder.addCase(getAppSettingsAsync.fulfilled, (state, action) => {
    state.appInterface = action.payload;
  });

  builder.addCase(getAppSettingsAsync.rejected, (state) => {
    state.appInterface = null;
  });

  builder.addCase(initAppSettingsAsync.fulfilled, (state, action) => {
    state.appInterface = action.payload.data;
  });

  builder.addCase(updateAppSettingsAsync.fulfilled, (state, action) => {
    state.appInterface = action.payload;
  });

  builder.addCase(getCGUAsync.fulfilled, (state, action) => {
    const contentState = convertFromRaw(JSON.parse(action.payload.content));

    state.cgu = EditorState.createWithContent(contentState);
  });

  builder.addCase(getCGUAsync.rejected, (state) => {
    state.cgu = EditorState.createEmpty();
  });

  builder.addCase(initCGUAsync.fulfilled, (state, action) => {
    const contentState = convertFromRaw(
      JSON.parse(action.payload.data.content)
    );

    state.cgu = EditorState.createWithContent(contentState);
  });

  builder.addCase(updateCGUAsync.fulfilled, (state, action) => {
    const contentState = convertFromRaw(JSON.parse(action.payload.content));

    state.cgu = EditorState.createWithContent(contentState);
  });
};

export default adminAsyncActions;
