import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import to from "await-to-js";

import { TReducerError } from "@/types/app";

import {
  IAPIDeleteResponseDeleteStaff,
  IAPIPatchResponseUpdateStaff,
  IAPIPostResponseAddStaff,
  IAPIResponseGetAllStaffs,
  TPagination,
} from "@/features/backoffice/types";

import handlingError from "@/utils/handlingError";

import {
  addStaff,
  deleteStaff,
  getAllStaffs,
  updateAdminPassword,
  updateModeratorPassword,
  updateStaff,
} from "@/features/backoffice/api";
import {
  TAdminInitialValues,
  TAdminPwdInitialValues,
} from "@/features/backoffice/pages/staff/Team.page";

export const getListStaffs = createAsyncThunk<
  IAPIResponseGetAllStaffs,
  undefined,
  {
    rejectValue: TReducerError;
  }
>("members/getAllStaffs", async (_param, { rejectWithValue }) => {
  const [error, data] = await to<IAPIResponseGetAllStaffs, AxiosError<any>>(
    getAllStaffs()
  );

  if (error) {
    return rejectWithValue(handlingError(error));
  }

  return data;
});

export const addStaffAsync = createAsyncThunk<
  IAPIPostResponseAddStaff,
  TAdminInitialValues,
  {
    rejectValue: TReducerError;
  }
>("members/addStaff", async (values, { rejectWithValue }) => {
  const [error, data] = await to<IAPIPostResponseAddStaff, AxiosError<any>>(
    addStaff(values)
  );

  if (error) {
    return rejectWithValue(handlingError(error));
  }

  return data;
});

export const updateStaffAsync = createAsyncThunk<
  IAPIPatchResponseUpdateStaff,
  { id: string; data: TAdminInitialValues },
  {
    rejectValue: TReducerError;
  }
>("members/updateStaff", async (values, { rejectWithValue }) => {
  const [error, data] = await to<IAPIPatchResponseUpdateStaff, AxiosError<any>>(
    updateStaff(values.id, values.data)
  );

  if (error) {
    return rejectWithValue(handlingError(error));
  }

  return data;
});

export const deleteStaffAsync = createAsyncThunk<
  IAPIDeleteResponseDeleteStaff,
  string,
  {
    rejectValue: TReducerError;
  }
>("members/deleteStaff", async (id, { rejectWithValue }) => {
  const [error, data] = await to<
    IAPIDeleteResponseDeleteStaff,
    AxiosError<any>
  >(deleteStaff(id));

  if (error) {
    return rejectWithValue(handlingError(error));
  }

  return data;
});

export const updateAdminPasswordAsync = createAsyncThunk<
  {
    code: string;
    message: string;
  },
  TAdminPwdInitialValues,
  {
    rejectValue: TReducerError;
  }
>("members/updatePassword", async (values, { rejectWithValue }) => {
  const [error, data] = await to<
    {
      code: string;
      message: string;
    },
    AxiosError<any>
  >(updateAdminPassword(values));

  if (error) {
    return rejectWithValue(handlingError(error));
  }

  return data;
});

export const updateModeratorPasswordAsync = createAsyncThunk<
  {
    code: string;
    message: string;
  },
  { id: string; password: string },
  {
    rejectValue: TReducerError;
  }
>("members/updatePassword", async (values, { rejectWithValue }) => {
  const [error, data] = await to<
    {
      code: string;
      message: string;
    },
    AxiosError<any>
  >(updateModeratorPassword(values));

  if (error) {
    return rejectWithValue(handlingError(error));
  }

  return data;
});
