import { axios } from "@/lib/axios";
import { TFaqInitialValues } from "../../containers/ImamQR/ImamQR.container";

import {
  IAPIDeleteFaq,
  IAPIGetFaqs,
  IAPIPatchFaq,
  IAPIPostFaq,
} from "../../types";

export const getFaqs = (): Promise<IAPIGetFaqs> => {
  return axios.get("/imam-qrs");
};

export const createFaq = (datas: TFaqInitialValues): Promise<IAPIPostFaq> => {
  return axios.post("/imam-qrs", {
    ...datas,
  });
};

export const updateFaq = (
  id: string,
  datas: TFaqInitialValues
): Promise<IAPIPatchFaq> => {
  return axios.patch(`/imam-qrs/${id}`, {
    ...datas,
  });
};

export const deleteFaq = (id: string): Promise<IAPIDeleteFaq> => {
  return axios.delete(`/imam-qrs/${id}`);
};
