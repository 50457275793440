import { combineReducers } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import authSlice from "./auth/auth.slice";
import memberSlice from "./members/member.slice";
import moderationSlice from "./moderation/moderation.slice";
import adminSlice from "./admins/admin.slice";
import settingsSlice from "./settings/settings.slice";
import imamSpeakersSlice from "./imamSpeakers/imamSpeakers.slice";
import appSlice from "./app/app.slice";

export const persistConfig = {
  key: "zawaj_sounnah_backoffice",
  storage,
  whitelist: ["app", "auth", "settings", "imamSpeakers"],
  blacklist: [],
};

const topReducer = combineReducers({
  app: appSlice,
  auth: authSlice,
  members: memberSlice,
  moderations: moderationSlice,
  admins: adminSlice,
  settings: settingsSlice,
  imamSpeakers: imamSpeakersSlice,
});

const rootReducer = (state: any, action: any) => {
  return topReducer(state, action);
};

export default persistReducer(persistConfig, rootReducer);
