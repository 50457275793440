import { axios } from "@/lib/axios";
import { TMemberInitialValues } from "../../pages/members/ProfileManagement.page";

import {
  IAPIGetDeletedProfiles,
  IAPIGetMembersDatas,
  IAPIGetProfileValidations,
  IAPIPostMember,
  IAPIResponseGetAllProfiles,
  TInfosHealthPayload,
  TInfosProfilePayload,
  TInfosReligiousAspectPayload,
  TInfosReligiousPracticePayload,
  TMaritalStatus,
  TMemberProfile,
} from "../../types";
import isEmpty from "@/utils/isEmpty";

export const getMemberDetails = (memberId: string): Promise<TMemberProfile> => {
  return axios.get(`/members/${memberId}/administration`);
};

export const getAllProfiles = ({
  search,
  limit,
  page,
}: {
  search?: string;
  limit?: number;
  page?: number;
} = {}): Promise<IAPIResponseGetAllProfiles> => {
  return axios.get("/members/all/accounts", {
    params: {
      limit,
      page,
      search,
    },
  });
};

export const getFilteredProfiles = ({
  member_id,
  username,
  email,
  gender,
  is_verified,
  has_subscription,
  limit,
  page,
}: {
  member_id?: string;
  username?: string;
  email?: string;
  gender?: "male" | "female";
  is_verified?: boolean;
  has_subscription?: boolean;
  limit?: number;
  page?: number;
} = {}): Promise<IAPIResponseGetAllProfiles> => {
  return axios.get("/members/filtered/accounts", {
    params: {
      member_id,
      username,
      email,
      gender,
      is_verified,
      has_subscription,
      limit,
      page,
    },
  });
};

export const getMembersDatas = ({
  country,
  citizenship,
  home_country,
  marital_status,
  gender,
  max_age,
  min_age,
  limit,
  page,
}: {
  country?: string;
  citizenship?: string;
  home_country?: string;
  marital_status?: TMaritalStatus;
  gender?: "male" | "female";
  max_age?: number;
  min_age?: number;
  limit?: number;
  page?: number;
} = {}): Promise<IAPIGetMembersDatas> => {
  return axios.get("/members/data/members", {
    params: {
      country,
      citizenship,
      home_country,
      marital_status,
      gender,
      max_age,
      min_age,
      limit,
      page,
    },
  });
};

export const getPendingProfiles = (): Promise<IAPIResponseGetAllProfiles> => {
  return axios.get("/members/pending/accounts");
};

export const getDeletedProfiles = ({
  phone_email,
  limit,
  page,
}: {
  phone_email?: string;
  limit?: number;
  page?: number;
} = {}): Promise<IAPIGetDeletedProfiles> => {
  return axios.get("/members/accounts/deleted", {
    params: {
      phone_email,
      limit,
      page,
    },
  });
};

export const approuveAccount = (id: string): Promise<TMemberProfile> => {
  return axios.patch(`/changes-requests/${id}`, {
    is_accepted: true,
  });
};

export const disApprouveAccount = (
  id: string,
  message: string
): Promise<TMemberProfile> => {
  return axios.patch(`/changes-requests/${id}`, {
    is_accepted: false,
    comment: message,
  });
};

export const deleteAccount = (
  id: string,
  password: string,
  message: string
): Promise<{
  code: string;
  message: string;
}> => {
  return axios.delete(`/members/${id}/by-admin`, {
    data: {
      password,
      message,
    },
  });
};

export const toggleAccountActiveness = (
  id: string,
  reason?: string
): Promise<{
  code: "string";
  message: "string";
}> => {
  return axios.patch(`/members/${id}/activeness`, {
    reason,
  });
};

export const banProfile = (
  id: string,
  reason?: string
): Promise<{
  code: "string";
  message: "string";
}> => {
  return axios.patch(`/members/${id}/ban`, {
    reason,
  });
};

export const unbanProfile = (
  id: string
): Promise<{
  code: "string";
  message: "string";
}> => {
  return axios.patch(`/members/${id}/unban`);
};

export const switchProfileToFree = (
  id: string,
  free: boolean
): Promise<TMemberProfile> => {
  return axios.patch(`/members/${id}/switch-account-to-free`, {
    free,
  });
};

export const resetMemberPassword = (
  memberId: string
): Promise<TMemberProfile> => {
  return axios.patch(`/members/${memberId}/force-password-reset`);
};

export const restoreMemberAccount = (
  memberId: string
): Promise<TMemberProfile> => {
  return axios.patch(`/members/${memberId}/restore-account`);
};

export const getProfileMember = (memberId: string): Promise<TMemberProfile> => {
  return axios.get(`/members/${memberId}/administration`);
};

// export const addMemberAccount = (
//   data: TMemberInitialValues
// ): Promise<IAPIPostMember> => {
//   return axios.post(`/members`, {
//     ...data,
//   });
// };

export const addMemberAccount = (
  data: TMemberInitialValues
): Promise<IAPIPostMember> => {
  return axios.post(`/members/create-free-account`, {
    ...data,
    username: isEmpty(data.username) ? undefined : data.username,
    age: isEmpty(data.age) ? undefined : data.age,
  });
};

export const editMemberAccount = (
  data: TMemberInitialValues
): Promise<TMemberProfile> => {
  return axios.patch(`/members/${data.id}/administration/update_infos`, {
    ...data,
  });
};

export const getProfileValidations = ({
  admin,
  member,
  status,
  limit,
  page,
}: {
  admin?: string;
  member?: string;
  status?: boolean;
  limit?: number;
  page?: number;
} = {}): Promise<IAPIGetProfileValidations> => {
  return axios.get(`/profile-validations`, {
    params: {
      admin,
      member,
      status,
      limit,
      page,
    },
  });
};

export const forcePasswordReset = (
  memberId: string,
  password: string
): Promise<TMemberProfile> => {
  return axios.patch(`/members/${memberId}/force-password-reset`, {
    password,
  });
};

export const updateMemberProfile = (
  memberId: string,
  data: TInfosProfilePayload
): Promise<TMemberProfile> => {
  return axios.patch(`/members/profile/${memberId}/profile-infos`, data);
};

export const updateMemberReligousAspect = (
  memberId: string,
  gender: "male" | "female",
  data: TInfosReligiousAspectPayload
): Promise<TMemberProfile> => {
  return axios.patch(
    `/members/profile/${gender}/${memberId}/religious-aspect-infos`,
    data
  );
};

export const updateMemberReligousPractice = (
  memberId: string,
  data: TInfosReligiousPracticePayload
): Promise<TMemberProfile> => {
  return axios.patch(
    `/members/profile/${memberId}/religious-practice-infos`,
    data
  );
};

export const updateMemberHealth = (
  memberId: string,
  data: TInfosHealthPayload
): Promise<TMemberProfile> => {
  return axios.patch(`/members/profile/${memberId}/health-infos`, data);
};
