import { TReducerError } from "@/types/app";
import { createSlice } from "@reduxjs/toolkit";
import asyncActions from "./member.asyncActions";

import { IMembersInitialState } from "./member.interfaces";

export const errorInitialValue: TReducerError = {
  message: "",
  value: null,
};

const initialState: IMembersInitialState = {
  listProfiles: null,
  listDeletedAccounts: null,
  listPendingProfiles: null,
  loading: "idle",
  errors: {
    message: "",
    value: null,
  },
};

export const memberSlice = createSlice({
  name: "member",
  initialState,
  reducers: {},
  extraReducers(builder) {
    asyncActions(builder);
  },
});

export const memberSliceActions = memberSlice.actions;

export default memberSlice.reducer;
