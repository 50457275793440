import { axios } from "@/lib/axios";

import { IAPIGetCGU, IAPIPatchCGU, IAPIPostCGU } from "../../types";

export const getCGU = (): Promise<IAPIGetCGU> => {
  return axios.get("/general-terms");
};

export const initCGU = (content: string): Promise<IAPIPostCGU> => {
  return axios.post("/general-terms", {
    content,
  });
};

export const updateCGU = (content: string): Promise<IAPIPatchCGU> => {
  return axios.patch("/general-terms", {
    content,
  });
};
