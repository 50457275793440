import { axios } from "@/lib/axios";

import {
  IAPIGetBusinessHours,
  IAPIGetModerationStatus,
  TTimeSlot,
} from "../../types";

export const getTimeSlots = (): Promise<IAPIGetBusinessHours> => {
  return axios.get("/time-slots");
};

export const updateTimeSlots = (
  datas: Array<TTimeSlot>
): Promise<IAPIGetBusinessHours> => {
  return axios.patch("/time-slots", datas);
};

export const getModerationStatus = (): Promise<IAPIGetModerationStatus> => {
  return axios.get("/time-slots/moderation-status");
};
