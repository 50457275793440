import { axios } from "@/lib/axios";

import { IAPIGetReports, IAPIPatchReportMessage } from "../../types";

export const getAllReports = ({
  limit,
  page,
}: {
  limit?: number;
  page?: number;
} = {}): Promise<IAPIGetReports> => {
  return axios.get("/reports", {
    params: {
      limit,
      page,
    },
  });
};

export const getFilteredReports = ({
  gender,
  start_date,
  end_date,
  reporter,
  defendant,
  limit,
  page,
}: {
  start_date?: string;
  end_date?: string;
  gender?: "male" | "female";
  reporter?: string;
  defendant?: string;
  limit?: number;
  page?: number;
} = {}): Promise<IAPIGetReports> => {
  return axios.get("/reports/search", {
    params: {
      gender,
      start_date,
      end_date,
      reporter,
      defendant,
      limit,
      page,
    },
  });
};

export const sendReportMessage = ({
  reportId,
  receiver,
  subject,
  message,
}: {
  reportId: string;
  receiver: string;
  subject: string;
  message: string;
}): Promise<IAPIPatchReportMessage> => {
  return axios.patch("/reports/send/message", {
    report: reportId,
    receiver,
    subject,
    message,
  });
};
