import { createSlice } from "@reduxjs/toolkit";

import { IAuthInitialState } from "./auth.interfaces";
import { TReducerError } from "@/types/app";

import authAsyncActions from "./auth.asyncAction";
import * as authAction from "./auth.actions";

export const errorInitialValue: TReducerError = {
  message: "",
  value: null,
};

const initialState: IAuthInitialState = {
  user: null,
  token: null,
  loading: "idle",
  errors: {
    message: "",
    value: null,
  },
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    ...authAction,
  },
  extraReducers(builder) {
    authAsyncActions(builder);
  },
});

export const authSliceActions = authSlice.actions;

export default authSlice.reducer;
