import { axios } from "@/lib/axios";
import { TAppInterfaceSettings } from "../../types";

import {
  IAPIGetAppInterface,
  IAPIPatchAppInterface,
  IAPIPostAppInterface,
} from "../../types/interfaces/api/appInterface";

export const getInterfaceSettings = (): Promise<IAPIGetAppInterface> => {
  return axios.get("/interface-settings");
};

export const initInterfaceSettings = (
  data: TAppInterfaceSettings
): Promise<IAPIPostAppInterface> => {
  return axios.post("/interface-settings", {
    ...data,
  });
};

export const updateInterfaceSettings = (
  data: TAppInterfaceSettings
): Promise<IAPIPatchAppInterface> => {
  return axios.patch("/interface-settings", {
    ...data,
  });
};
