import { ActionReducerMapBuilder } from "@reduxjs/toolkit";

import { IModerationInitialState } from "./moderation.interfaces";
import {
  createModerationTemplate,
  getListModerationTemplates,
  updateModerationTemplate,
} from "./moderation.services";

import { errorInitialValue } from "./moderation.slice";

const moderationAsyncActions = (
  builder: ActionReducerMapBuilder<IModerationInitialState>
) => {
  builder.addCase(getListModerationTemplates.fulfilled, (state, action) => {
    state.loading = "idle";
    state.errors = errorInitialValue;
    state.moderationTemplates = action.payload;
  });

  builder.addCase(getListModerationTemplates.rejected, (state, action) => {
    state.loading = "failed";
    state.errors = action.payload;
  });

  builder.addCase(createModerationTemplate.fulfilled, (state, action) => {
    state.loading = "idle";
    state.errors = errorInitialValue;

    if (state.moderationTemplates) {
      state.moderationTemplates.data.push(action.payload.data);
      state.moderationTemplates.count++;
    }
  });

  builder.addCase(createModerationTemplate.rejected, (state, action) => {
    state.loading = "failed";
    state.errors = action.payload;
  });

  builder.addCase(updateModerationTemplate.fulfilled, (state, action) => {
    state.loading = "idle";
    state.errors = errorInitialValue;

    if (state.moderationTemplates) {
      const index = state.moderationTemplates.data.findIndex(
        (data) => data.id === action.payload.id
      );
      let data = state.moderationTemplates.data;
      data[index] = action.payload;

      if (index) {
        state.moderationTemplates.data = data;
      }
    }
  });

  builder.addCase(updateModerationTemplate.rejected, (state, action) => {
    state.loading = "failed";
    state.errors = action.payload;
  });
};

export default moderationAsyncActions;
