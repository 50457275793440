import { PayloadAction } from "@reduxjs/toolkit";
import { ISettingsInitialState } from "./settings.interfaces";

export const setBusinessHourAction = (
  state: ISettingsInitialState,
  action: PayloadAction<{ name: string; id: string; value: string }>
) => {
  const findIndex = state.businessHours.findIndex(
    (timeSlot) => timeSlot.id === action.payload.id
  );

  state.businessHours[findIndex] = {
    ...state.businessHours[findIndex],
    [action.payload.name]: action.payload.value,
  };
};

export const setBusinessHolydayAction = (
  state: ISettingsInitialState,
  action: PayloadAction<string>
) => {
  const findIndex = state.businessHours.findIndex(
    (timeSlot) => timeSlot.id === action.payload
  );

  state.businessHours[findIndex] = {
    ...state.businessHours[findIndex],
    is_holiday: !state.businessHours[findIndex].is_holiday,
  };
};

export const deletePlanAction = (
  state: ISettingsInitialState,
  action: PayloadAction<string>
) => {
  if (state.plans) {
    state.plans.items = state.plans.items.filter(
      (item) => item.id !== action.payload
    );
  }
};
