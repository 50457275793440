import { axios } from "@/lib/axios";
import { TPlanInitialValues } from "../../containers/Settings/Plans/Plans.container";

import { IAPIGetPlans, IAPIPatchPlan, IAPIPostPlan } from "../../types";

export const getPlans = (): Promise<IAPIGetPlans> => {
  return axios.get("/subscription-plans", {
    params: {
      is_active: true,
    },
  });
};

export const createPlans = (
  datas: TPlanInitialValues
): Promise<IAPIPostPlan> => {
  return axios.post("/subscription-plans", {
    ...datas,
  });
};

export const editPlan = (
  id: string,
  datas: Partial<TPlanInitialValues>
): Promise<IAPIPatchPlan> => {
  return axios.patch(`/subscription-plans/${id}`, {
    ...datas,
  });
};
