export const API_URL = process.env.REACT_APP_API_URL as string;
export const SOCKET_URL = process.env.REACT_APP_SOCKET_URL as string;

export const currencies = { eur: "euro", usd: "dollar" };

export const levelReligiousEducations = {
  none: "Aucun",
  beginner: "Débutant(e)",
  intermediate: "Intermédiaire",
  advanced: "Avancé(e)",
  senior: "Confirmé(e)",
  not_pronounced: "Ne se prononce pas",
};

export const maritalStatus = {
  none: "Aucun",
  "never-married": "Jamais marié",
  married: "Marié",
  divorced: "Divorcé",
  widow: "Voeuf",
  not_pronounced: "Non prononcé",
};

export const femaleDressCodes = {
  not_wear_headscarf: "Ne porte pas encore le voile",
  wear_abaya: "Porte le abaya",
  wear_hijab: "Porte le Hijab",
  wear_jilbab: "Porte le Jilbab",
  wear_niqab: "Porte le Niqab",
  wear_turban: "Porte le turban",
};

export const listItemsPerPage = [
  {
    label: 5,
    value: 5,
  },
  {
    label: 10,
    value: 10,
  },
  {
    label: 25,
    value: 25,
  },
  {
    label: 50,
    value: 50,
  },
  {
    label: 75,
    value: 75,
  },
  {
    label: 100,
    value: 100,
  },
];
