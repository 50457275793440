import { axios } from "@/lib/axios";

import { IAPIGetSubscriptions } from "../../types";

export const getAllSubscriptions = ({
  phone_email,
  limit,
  page,
}: {
  phone_email?: string;
  limit?: number;
  page?: number;
} = {}): Promise<IAPIGetSubscriptions> => {
  return axios.get("/subscriptions", {
    params: {
      phone_email,
      limit,
      page,
    },
  });
};
