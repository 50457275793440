import { axios } from "@/lib/axios";

import { IAPIResponseLogin } from "../types/interfaces";

export const login = (
  username: string,
  password: string
): Promise<IAPIResponseLogin> => {
  return axios.patch("/admins/auth/login", {
    username,
    password,
  });
};

export const askOTP = (
  email: string
): Promise<{
  code: string;
  message: string;
}> => {
  return axios.patch("/admins/auth/reset-password-token", {
    email,
  });
};

export const changePassword = ({
  email,
  token,
  password,
}: {
  email: string;
  token: string;
  password: string;
}): Promise<{
  code: string;
  message: string;
}> => {
  return axios.patch("/admins/auth/reset-password", {
    email,
    token,
    password,
  });
};
