import isEmpty from "@/utils/isEmpty";
import { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { profile } from "console";

import { IMembersInitialState } from "./member.interfaces";

import {
  addMemberAsync,
  approuveAccountAsync,
  banProfileAsync,
  deleteAccountAsync,
  disApprouveAccountAsync,
  getFilteredProfilesAsync,
  getListAccountsMember,
  getListDeletedAccountsMember,
  getListPendingAccountsMember,
  resetMemberPasswordAsync,
  restoreMemberAccountAsync,
  toggleAccountActivenessAsync,
  unbanProfileAsync,
  switchProfileToFreeAsync,
  editMemberAsync,
} from "./member.services";

import { errorInitialValue } from "./member.slice";

const asyncActions = (
  builder: ActionReducerMapBuilder<IMembersInitialState>
) => {
  builder.addCase(getFilteredProfilesAsync.pending, (state) => {
    state.loading = "pending";
    state.errors = errorInitialValue;
  });

  builder.addCase(getFilteredProfilesAsync.fulfilled, (state, action) => {
    state.loading = "idle";
    state.errors = errorInitialValue;
    state.listProfiles = action.payload;
  });

  builder.addCase(getFilteredProfilesAsync.rejected, (state, action) => {
    state.loading = "failed";
    state.errors = action.payload;
  });

  builder.addCase(getListAccountsMember.pending, (state) => {
    state.loading = "pending";
    state.errors = errorInitialValue;
  });

  builder.addCase(getListAccountsMember.fulfilled, (state, action) => {
    state.loading = "idle";
    state.errors = errorInitialValue;
    state.listProfiles = action.payload;
  });

  builder.addCase(getListAccountsMember.rejected, (state, action) => {
    state.loading = "failed";
    state.errors = action.payload;
  });

  builder.addCase(getListPendingAccountsMember.fulfilled, (state, action) => {
    state.loading = "idle";
    state.errors = errorInitialValue;
    state.listPendingProfiles = {
      ...action.payload,
      data: action.payload.data.filter((data) => data.rate_of_completion >= 10),
    };
  });

  builder.addCase(getListPendingAccountsMember.rejected, (state, action) => {
    state.loading = "failed";
    state.errors = action.payload;
  });

  builder.addCase(getListDeletedAccountsMember.fulfilled, (state, action) => {
    state.loading = "idle";
    state.errors = errorInitialValue;
    state.listDeletedAccounts = action.payload;
  });

  builder.addCase(getListDeletedAccountsMember.rejected, (state, action) => {
    state.loading = "failed";
    state.errors = action.payload;
  });

  builder.addCase(approuveAccountAsync.fulfilled, (state, action) => {
    if (state.listPendingProfiles) {
      state.listPendingProfiles.data = state.listPendingProfiles.data.filter(
        (member) => member.id !== action.payload.id
      );
    }
  });

  builder.addCase(disApprouveAccountAsync.fulfilled, (state, action) => {
    if (state.listPendingProfiles) {
      state.listPendingProfiles.data = state.listPendingProfiles.data.map(
        (member) =>
          member.id === action.payload.id
            ? {
                ...member,
                is_reviewed: true,
                account: { ...member.account, changes_requests_updated: false },
              }
            : member
      );
    }
  });

  builder.addCase(deleteAccountAsync.fulfilled, (state, action) => {
    if (state.listProfiles) {
      state.listProfiles.data = state.listProfiles.data.filter(
        (member) => member.id !== action.meta.arg.id
      );
    }
  });

  builder.addCase(toggleAccountActivenessAsync.fulfilled, (state, action) => {
    if (state.listProfiles) {
      const index = state.listProfiles.data.findIndex(
        (member) => member.id === action.meta.arg.id
      );

      if (typeof action.meta.arg.reason === "string") {
        state.listProfiles.data[index].account.activeness.reason =
          action.meta.arg.reason;
      } else if (
        state.listProfiles.data[index].account.activeness.reason !== undefined
      ) {
        state.listProfiles.data[index].account.activeness.reason = null;
      }
    }
  });

  builder.addCase(banProfileAsync.fulfilled, (state, action) => {
    if (state.listProfiles) {
      state.listProfiles.data = state.listProfiles.data.map((member) =>
        member.id === action.meta.arg.id
          ? {
              ...member,
              account: {
                ...member.account,
                banned: {
                  is_banned: !!!member.account.banned?.is_banned,
                  reason: action.meta.arg.reason as string,
                },
              },
            }
          : member
      );
    }
  });

  builder.addCase(unbanProfileAsync.fulfilled, (state, action) => {
    if (state.listProfiles) {
      state.listProfiles.data = state.listProfiles.data.map((member) =>
        member.id === action.meta.arg
          ? {
              ...member,
              account: {
                ...member.account,
                banned: {
                  is_banned: !!!member.account.banned?.is_banned,
                  reason: null,
                },
              },
            }
          : member
      );
    }
  });

  builder.addCase(switchProfileToFreeAsync.fulfilled, (state, action) => {
    if (state.listProfiles) {
      state.listProfiles.data = state.listProfiles.data.map((profile) =>
        profile.id === action.payload.id ? action.payload : profile
      );
    }
  });

  builder.addCase(resetMemberPasswordAsync.fulfilled, (state, action) => {
    if (state.listProfiles) {
      const index = state.listProfiles.data.findIndex(
        (member) => member.id === action.meta.arg
      );

      state.listProfiles.data[index] = {
        ...state.listProfiles.data[index],
        ...action.payload,
      };
    }
  });

  builder.addCase(restoreMemberAccountAsync.fulfilled, (state, action) => {
    if (state.listDeletedAccounts) {
      state.listDeletedAccounts.data = state.listDeletedAccounts.data.filter(
        (member) => member.id !== action.meta.arg
      );
    }
  });

  builder.addCase(addMemberAsync.fulfilled, (state, action) => {
    if (state.listProfiles) {
      state.listProfiles.data = [
        action.payload.data,
        ...state.listProfiles.data,
      ];
    }
  });

  builder.addCase(editMemberAsync.fulfilled, (state, action) => {
    if (state.listProfiles) {
      state.listProfiles.data = state.listProfiles.data.map((profile) =>
        profile.id === action.payload.id ? action.payload : profile
      );
      // state.listProfiles.data = [
      //   action.payload.data,
      //   ...state.listProfiles.data,
      // ];
    }
  });
};

export default asyncActions;
