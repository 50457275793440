import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import to from "await-to-js";

import { TReducerError } from "@/types/app";

import handlingError from "@/utils/handlingError";

import {
  IAPIGetAppInterface,
  IAPIGetBusinessHours,
  IAPIGetCGU,
  IAPIGetPlans,
  IAPIPatchAppInterface,
  IAPIPatchCGU,
  IAPIPatchEthnicities,
  IAPIPatchPlan,
  IAPIPostAppInterface,
  IAPIPostCGU,
  IAPIPostEthnicities,
  IAPIPostPlan,
  IAPIResponseGetEthnicities,
  TAppInterfaceSettings,
  TTimeSlot,
} from "@/features/backoffice/types";

import {
  createEthnicity,
  createPlans,
  editPlan,
  getCGU,
  getEthnicities,
  getInterfaceSettings,
  getPlans,
  getTimeSlots,
  initCGU,
  initInterfaceSettings,
  updateCGU,
  updateEthnicity,
  updateInterfaceSettings,
  updateTimeSlots,
} from "@/features/backoffice/api";

import { TPlanInitialValues } from "@/features/backoffice/containers/Settings/Plans/Plans.container";

export const getBusinessHoursAsync = createAsyncThunk<
  IAPIGetBusinessHours,
  undefined,
  {
    rejectValue: TReducerError;
  }
>("businessHours/get", async (_param, { rejectWithValue }) => {
  const [error, data] = await to<IAPIGetBusinessHours, AxiosError<any>>(
    getTimeSlots()
  );

  if (error) {
    return rejectWithValue(handlingError(error));
  }

  return data;
});

export const updateBusinessHoursAsync = createAsyncThunk<
  IAPIGetBusinessHours,
  Array<TTimeSlot>,
  {
    rejectValue: TReducerError;
  }
>("businessHours/update", async (datas, { rejectWithValue }) => {
  const [error, data] = await to<IAPIGetBusinessHours, AxiosError<any>>(
    updateTimeSlots(datas)
  );

  if (error) {
    return rejectWithValue(handlingError(error));
  }

  return data;
});

export const getEthnicitiesAsync = createAsyncThunk<
  IAPIResponseGetEthnicities,
  undefined,
  {
    rejectValue: TReducerError;
  }
>("ethnicities/get", async (_param, { rejectWithValue }) => {
  const [error, data] = await to<IAPIResponseGetEthnicities, AxiosError<any>>(
    getEthnicities()
  );

  if (error) {
    return rejectWithValue(handlingError(error));
  }

  return data;
});

export const createEthnicitiesAsync = createAsyncThunk<
  IAPIPostEthnicities,
  { name: string },
  {
    rejectValue: TReducerError;
  }
>("ethnicities/create", async (param, { rejectWithValue }) => {
  const [error, data] = await to<IAPIPostEthnicities, AxiosError<any>>(
    createEthnicity(param)
  );

  if (error) {
    return rejectWithValue(handlingError(error));
  }

  return data;
});

export const updateEthnicitiesAsync = createAsyncThunk<
  IAPIPatchEthnicities,
  { id: string; name: string },
  {
    rejectValue: TReducerError;
  }
>("ethnicities/update", async (param, { rejectWithValue }) => {
  const [error, data] = await to<IAPIPatchEthnicities, AxiosError<any>>(
    updateEthnicity(param)
  );

  if (error) {
    return rejectWithValue(handlingError(error));
  }

  return data;
});

export const getPlansAsync = createAsyncThunk<
  IAPIGetPlans,
  undefined,
  {
    rejectValue: TReducerError;
  }
>("plans/get", async (_param, { rejectWithValue }) => {
  const [error, data] = await to<IAPIGetPlans, AxiosError<any>>(getPlans());

  if (error) {
    return rejectWithValue(handlingError(error));
  }

  return data;
});

export const createPlansAsync = createAsyncThunk<
  IAPIPostPlan,
  TPlanInitialValues,
  {
    rejectValue: TReducerError;
  }
>("plans/create", async (values, { rejectWithValue }) => {
  const [error, data] = await to<IAPIPostPlan, AxiosError<any>>(
    createPlans(values)
  );

  if (error) {
    return rejectWithValue(handlingError(error));
  }

  return data;
});

export const updatePlansAsync = createAsyncThunk<
  IAPIPatchPlan,
  { id: string; datas: Partial<TPlanInitialValues> },
  {
    rejectValue: TReducerError;
  }
>("plans/update", async (values, { rejectWithValue }) => {
  const [error, data] = await to<IAPIPatchPlan, AxiosError<any>>(
    editPlan(values.id, values.datas)
  );

  if (error) {
    return rejectWithValue(handlingError(error));
  }

  return data;
});

export const getAppSettingsAsync = createAsyncThunk<
  IAPIGetAppInterface,
  undefined,
  {
    rejectValue: TReducerError;
  }
>("appSettings/get", async (_, { rejectWithValue }) => {
  const [error, data] = await to<IAPIGetAppInterface, AxiosError<any>>(
    getInterfaceSettings()
  );

  if (error) {
    return rejectWithValue(handlingError(error));
  }

  return data;
});

export const initAppSettingsAsync = createAsyncThunk<
  IAPIPostAppInterface,
  TAppInterfaceSettings,
  {
    rejectValue: TReducerError;
  }
>("appSettings/init", async (datas, { rejectWithValue }) => {
  const [error, data] = await to<IAPIPostAppInterface, AxiosError<any>>(
    initInterfaceSettings(datas)
  );

  if (error) {
    return rejectWithValue(handlingError(error));
  }

  return data;
});

export const updateAppSettingsAsync = createAsyncThunk<
  IAPIPatchAppInterface,
  TAppInterfaceSettings,
  {
    rejectValue: TReducerError;
  }
>("appSettings/update", async (datas, { rejectWithValue }) => {
  const [error, data] = await to<IAPIPatchAppInterface, AxiosError<any>>(
    updateInterfaceSettings(datas)
  );

  if (error) {
    return rejectWithValue(handlingError(error));
  }

  return data;
});

export const getCGUAsync = createAsyncThunk<
  IAPIGetCGU,
  undefined,
  {
    rejectValue: TReducerError;
  }
>("cgu/get", async (_, { rejectWithValue }) => {
  const [error, data] = await to<IAPIGetCGU, AxiosError<any>>(getCGU());

  if (error) {
    return rejectWithValue(handlingError(error));
  }

  return data;
});

export const initCGUAsync = createAsyncThunk<
  IAPIPostCGU,
  string,
  {
    rejectValue: TReducerError;
  }
>("cgu/init", async (content, { rejectWithValue }) => {
  const [error, data] = await to<IAPIPostCGU, AxiosError<any>>(
    initCGU(content)
  );

  if (error) {
    return rejectWithValue(handlingError(error));
  }

  return data;
});

export const updateCGUAsync = createAsyncThunk<
  IAPIPatchCGU,
  string,
  {
    rejectValue: TReducerError;
  }
>("cgu/update", async (content, { rejectWithValue }) => {
  const [error, data] = await to<IAPIPatchCGU, AxiosError<any>>(
    updateCGU(content)
  );

  if (error) {
    return rejectWithValue(handlingError(error));
  }

  return data;
});
